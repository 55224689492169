<template>
  <div class="flex flex-col w-full">
    <span
      :class="`w-1/4 shadow-lg p-3 text-white
        text-sm text-center absolute ${responseBg}`"
      style="left: 67%"
      v-if="response"
    >
      {{ response }}
    </span>
    <card class="w-full flex flex-col mt-2 p-5">
      <div class="w-full gap-5 mb-6 flex">
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <Icon
              class-name="text-mediumSeaGreen -mb-2"
              icon-name="template-union"
              size="l"
            />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase"
                >Total Work Count</span
              >
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                totalWorkCount
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <Icon
              class-name="text-blueCrayola -mb-2"
              icon-name="icon-requests"
              size="l"
            />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Total number of hours</span
              >
              <span class="text-lg font-extrabold text-blueCrayola">{{
                totalWorkHours
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <card class="p-2" style="height: 40px">
              <Icon
                class-name="text-chartPurple -mb-2"
                icon-name="icon-money"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">
                Total Customers Impacted
              </span>
              <span class="text-lg font-extrabold text-chartPurple">{{
                totalCustomersImpacted
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
      </div>
      <template v-slot:footer>
        <CardFooter
          :buttoncard="isEligible"
          buttontext="Pay Request"
          module="overtime"
          printcard="true"
          reloadcard="true"
          @reload="reload"
          @modalRequest="requestModal = true"
        >
          <template v-slot:search>
            <SearchInput
              placeholder="Search"
              class="search"
              style="width: 300px; margin-top: 0.1rem"
            />
          </template>
          <template v-slot:calendar>
            <div class="flex" style="width: 180px; padding: 5px 8px">
              <icon icon-name="calendar" class="icon mt-1" />
              <div
                class="border-r"
                style="width: 1px; margin: 8px 8px; border-color: #321c3b"
              />
              <div
                class="text-darkPurple h-full"
                style="width: 91px; font-size: 16px; margin: 4px 6px"
              >
                Year to Date
              </div>
              <icon icon-name="caret-down" class="icon mt-1" />
            </div>
          </template>
        </CardFooter>
      </template>
    </card>
    <card class="p-5 mt-1">
      <div class="flex-col">
        <Table
          v-if="requests.length > 0 || loading"
          :headers="tableHeaders"
          :items="requests"
          aria-label="overtime-pay"
          class="w-full mt-4 mb-4"
          :has-number="false"
          :loading="loading"
          :pagination-list="metaData"
          @page="handlePage($event)"
          page-sync
          @itemsPerPage="handleItemsPerPage($event)"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.paySetting">
              <span> {{ item.data.paySetting.nameOnPayslip }} </span>
            </div>
            <div v-if="item.plannedTask">
              <span> {{ item.data.plannedTask }} </span>
            </div>
            <div v-if="item.description">
              <span> {{ item.data.description }} </span>
            </div>
            <div v-else-if="item.startDate">
              <span>
                {{
                  $DATEFORMAT(new Date(item.data.startDate), "MMMM dd, yyyy")
                }}
              </span>
            </div>
            <div v-else-if="item.endDate">
              <span>
                {{ $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy") }}
              </span>
            </div>
            <div v-else-if="item.ratePerHour">
              <span> {{ Math.ceil(item.data.ratePerHour) }} </span>
            </div>
            <div v-else-if="item.overtimeCost">
              <span> {{ convertToCurrency(item.data.overtimeCost) }} </span>
            </div>
            <div
              v-else-if="item.payApprovalStatus"
              style="width: 100px"
              class="p-3"
              :class="{
                pending: item.data.payApprovalStatus === 'pending',
                approved: item.data.payApprovalStatus === 'approved',
                disapproved: item.data.payApprovalStatus === 'disapproved',
              }"
            >
              <div class="w-auto capitalize flex flex-col">
                <p class="text-sm font-semibold">
                  {{ item.data.payApprovalStatus | capitalize }}
                </p>
              </div>
            </div>
                <span v-else-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <div
                        class="w-full"
                        @click="
                          viewDetails()
                        "
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-darkPurple"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div class="w-full" @click="openApproval(item.data.id)">
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </span>
          </template>
        </Table>
        <div
          v-else
          class="w-full flex flex-col mt-4 p-2 justify-center items-center"
        >
          <p class="text-base my-2">
            There are no pending overtime requests at the moment.
          </p>
        </div>
      </div>
    </card>
    <RightSideBar
      v-if="requestModal"
      @submit="addPayRequest"
      @close="requestModal = false"
      button-class="bg-dynamicBackBtn text-white"
      cancel="Close"
      submit="Submit"
    >
      <template v-slot:title>
        <div class="w-full flex flex-col">
          <p class="text-darkPurple mb-4">Pay Requests</p>
          <p class="text-darkPurple text-base font-normal">
            You can select a planned work to request for your overtime payment.
          </p>
        </div>
      </template>
      <scroll-container height="420px">
        <card
          class="w-11/12 ml-3 flex flex-col p-4 mt-1 mb-3"
          v-for="(lists, index) in requestList"
          :key="index"
        >
          <checkbox
            checkbox-size="height:16px; width:16px; margin-top: 5px;"
            :value="lists.id"
            v-model="plannedWorkIds"
          />
          <div class="w-full flex mt-2">
            <div
              class="text-xs font-semibold flex flex-grow uppercase text-romanSilver"
            >
              Work Title:
            </div>
            <div class="flex justify-end font-semibold text-base">
              {{ lists.plannedTask }}
            </div>
          </div>
          <div class="w-full flex mt-2">
            <div
              class="text-xs font-semibold flex flex-grow uppercase text-romanSilver"
            >
              Overtime Due:
            </div>
            <div class="flex justify-end font-semibold text-base">
              {{ convertToCurrency(lists.overtimeCost) }}
            </div>
          </div>
          <div class="w-full flex mt-2">
            <div
              class="text-xs font-semibold flex flex-grow uppercase text-romanSilver"
            >
              Start Date:
            </div>
            <div class="flex justify-end font-semibold text-base">
              {{ $DATEFORMAT(new Date(lists.startDate), "MMMM dd, yyyy") }}
            </div>
          </div>
          <div class="w-full flex mt-2">
            <div
              class="text-xs font-semibold flex flex-grow uppercase text-romanSilver"
            >
              End Date:
            </div>
            <div class="flex justify-end font-semibold text-base">
              {{ $DATEFORMAT(new Date(lists.endDate), "MMMM dd, yyyy") }}
            </div>
          </div>
        </card>
      </scroll-container>
    </RightSideBar>

      <ApprovalProgress ref="approval_status" mode="ess" />
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";
import Menu from "@/components/Menu";

export default {
  name: "OvertimePay",
  components: {
    Card,
    Icon,
    CardFooter,
    RightSideBar,
    Menu,
    Checkbox,
    Table,
    ScrollContainer,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
  },
  props: {
    isEligible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      requestModal: false,
      success: "",
      query: "&page=1&perPage=50",
      requests: [],
      requestList: [],
      plannedWorkIds: [],
      totalWorkHours: 0,
      totalCustomersImpacted: 0,
      totalWorkCount: 0,
      loading: true,
      response: "",
      tableHeaders: [
        { title: "Category", value: "paySetting" },
        { title: "Work Title", value: "plannedTask" },
        { title: "Description", value: "description" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "Rate/Hr", value: "ratePerHour" },
        { title: "Amount(₦)", value: "overtimeCost" },
        { title: "Status", value: "payApprovalStatus" },
        { title: "", value: "id", image: true },
      ],
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    viewDetails() {
        this.$router.push({
          name: "EssOvertimeDetails",
          params: { id: this.$AuthUser.id },
          query: { overtimeType: "overtimePay", type: '', name: `Overtime Pay` },
        });
    },
    openApproval(payload) {
      return this.$refs.approval_status.toggle(payload);
    },
    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.query = `${pageNumber}${itemPage}`;
      this.payRequests();
    },

    async payRequests() {
      try {
        this.loading = true;
        const response = await this.$_getPayRequestWork(this.$AuthUser.id,this.query);
        this.requests = response.data.requests;
        this.metaData = response.data.meta;

        this.totalWorkHours = Math.ceil(response.data.work.totalWorkHours);
        this.totalWorkCount = Math.ceil(response.data.work.workCount);
        this.totalCustomersImpacted = Math.ceil(
          response.data.work.totalCustomersImpacted
        );
        this.loading = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    reload() {
      this.query = "&page=1&perPage=50";
      this.payRequests();
    },
    payRequestList() {
      const userId = this.$AuthUser.id;
      this.$_getOvertimePayRequestWork(userId).then((result) => {
        this.requestList = result.data.plannedWorks;
      });
    },
    addPayRequest() {
      const payLoad = {
        plannedWorkIds: this.plannedWorkIds,
        requests: [],
        userId: this.$AuthUser.id,
        employeeId: this.$AuthUser.id,
      };
      this.$_createPayRequest(payLoad)
        .then((result) => {
          this.$toasted.success(result.data.message, { duration: 5000 });
          this.requestModal = false;
          this.payRequests();
        })
        .catch((error) => {
          this.$toasted.error(error.result.data.message, { duration: 5000 });
        });
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
  },
  mounted() {
    this.payRequests("pending");
    this.payRequestList();
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
